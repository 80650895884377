import axios from 'axios'

const token = localStorage.getItem('user-token')
const headersFormData = { headers: { 'Content-Type': 'multipart/form-data;', Authorization: `${token}` } }
const headers = { headers: { Authorization: `${token}` } }
const BASE_API = process.env.VUE_APP_BASE_API
const name = 'associate'

export default {
  create (data) {
    return axios.post(`${BASE_API}/${name}/create`, data, headers)
  },
  update (data, id) {
    return axios.post(`${BASE_API}/${name}/update/${id}`, data, headers)
  },
  getPaginated (data, page) {
    return axios.post(
      `${BASE_API}/${name}/paginate?page=${page}`,
      data,
      headers
    )
  },
  getById (id) {
    return axios.get(`${BASE_API}/${name}/${id}`, headers)
  },
  getExport (data) {
    return axios.post(`${BASE_API}/${name}/export`, data, {
      ...headers,
      ...{ responseType: 'blob' }
    })
  },
  delete (id) {
    return axios.post(`${BASE_API}/${name}/destroy/${id}`, {}, headers)
  }
}
