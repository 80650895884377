const mapped = {
  bankList: [
    { text: 'Banco Bradesco', value: '237' },
    { text: 'Banco do Brasil', value: '1' },
    { text: 'Banco Itaú', value: '341' },
    { text: 'Banco Mercantil do Brasil', value: '389' },
    { text: 'Banco Safra', value: '422' },
    { text: 'Banco Santander', value: '33' },
    { text: 'Banco Tribanco', value: '634' },
    { text: 'Caixa Econômica Federal', value: '104' }
  ]
}

export default mapped
