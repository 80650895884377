<template>
  <v-container id="bankEmployees" tag="section">
     <!-- =======================================================
                                Snackbar
    ============================================================-->
   <v-snackbar v-model="snackbar.active" centered vertical>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.active = false">OK</v-btn>
      </template>
    </v-snackbar>

    <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <v-dialog v-model="openDialog" width="1000">
      <v-card>
        <v-card-title class="`headline green white--text">

          Dados do colaborador
           <v-spacer />

          <v-btn text @click="clearFields()"><v-icon large color="white">mdi-close</v-icon></v-btn>

        </v-card-title>
        <v-divider></v-divider>

        <div class="pa-5">
          <v-card class="pa-5 mb-5">
            <v-row class="d-flex">
              <v-col cols="12" sm="12">
                <strong>Dados básicos</strong>
                <div>Alguns dados pessoais</div>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  :label="`${formFields.name.label} *`"
                  v-model="formFields.name.value"
                  :rules="formFields.name.rules"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.cpf.label} *`"
                  v-model="formFields.cpf.value"
                  :rules="formFields.cpf.rules"
                  v-mask="'###.###.###-##'"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.rg.label} *`"
                  v-model="formFields.rg.value"
                  :rules="formFields.rg.rules"
                />
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" sm="8">
                <v-text-field
                  outlined
                  :label="`${formFields.email.label} *`"
                  v-model="formFields.email.value"
                  :rules="formFields.email.rules"
                  type="email"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  :label="`${formFields.phone.label} *`"
                  v-model="formFields.phone.value"
                  :rules="formFields.phone.rules"
                  type="tel"
                  v-mask="'(##) #####-####'"
                />
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.date_birth.label} *`"
                  v-model="formFields.date_birth.value"
                  :rules="formFields.date_birth.rules"
                  v-mask="'##/##/####'"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-combobox
                  clearable
                  outlined
                  :label="`${formFields.marital_status.label}
                  *`"
                  v-model="formFields.marital_status.value"
                  :rules="formFields.marital_status.rules"
                  :items="maritalStatusOptions"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.nationality.label} *`"
                  v-model="formFields.nationality.value"
                  :rules="formFields.nationality.rules"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-combobox
                  clearable
                  outlined
                  :label="`${formFields.status.label}`"
                  v-model="formFields.status.value"
                  :rules="formFields.status.rules"
                  :items="statusOptions"
                  :hint="formFields.status.value?.value === 'ASSOCIATE_ENABLED' && formFields.currentStatus.value === 'WAITING_VALIDATION' ? 'Será enviado um email avisando ao associado que o cadastro foi aprovado.' : ''"
                  persistent-hint
                  />
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-5 mb-5">
            <v-row class="d-flex">
              <v-col cols="12" sm="12">
                <strong>Dados profissionais</strong>
                <div>Informações do banco onde você trabalha</div>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" sm="9">
                <v-combobox clearable outlined :label="`${formFields.bank_code.label} *`" v-model="formFields.bank_code.value" :rules="formFields.bank_code.rules" :items="bankOptions" />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.bank_agency.label} *`"
                  v-model="formFields.bank_agency.value"
                  :rules="formFields.bank_agency.rules" v-mask="'####'" counter="4"
                />
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.bank_registration_number.label} *`"
                  v-model="formFields.bank_registration_number.value"
                  :rules="formFields.bank_registration_number.rules"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.bank_job_title.label} *`"
                  v-model="formFields.bank_job_title.value"
                  :rules="formFields.bank_job_title.rules"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.date_admission.label} *`"
                  v-model="formFields.date_admission.value"
                  :rules="formFields.date_admission.rules" v-mask="'##/##/####'"
                />
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-5 mb-5">
            <v-row class="d-flex">
              <v-col cols="12" sm="12">
                <strong>Endereço</strong>
                <div>Onde você mora</div>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.zipcode.label} *`"
                  v-model="formFields.zipcode.value"
                  :rules="formFields.zipcode.rules" @blur="zipcodeLookup" @input="zipcodeLookup" v-mask="'##.###-###'" hint="Buscar endereço pelo CEP" persistent-hint
                />
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field
                  outlined
                  :label="`${formFields.street.label} *`"
                  v-model="formFields.street.value"
                  :rules="formFields.street.rules"
                />
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  outlined
                  :label="`${formFields.number.label} *`"
                  v-model="formFields.number.value"
                  :rules="formFields.number.rules" ref="addressNumber"
                />
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.complement.label}`"
                  v-model="formFields.complement.value"
                  :rules="formFields.complement.rules"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.district.label} *`"
                  v-model="formFields.district.value"
                  :rules="formFields.district.rules"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.city.label} *`"
                  v-model="formFields.city.value"
                  :rules="formFields.city.rules"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  :label="`${formFields.state.label} *`"
                  v-model="formFields.state.value"
                  :rules="formFields.state.rules"
                />
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-5 mb-5">
            <v-row class="d-flex">
              <v-col cols="12" sm="12">
                <strong>Dependentes</strong>
                <div>Informações básicas dos seus dependentes</div>
              </v-col>
            </v-row>

            <v-row class="d-flex" v-for="(field, index) in formDependentFields">
              <v-col cols="12" sm="4">
                <v-text-field outlined :label="`${field.name.label} *`" v-model="field.name.value" :rules="field.name.rules" />
              </v-col>
              <v-col cols="12" sm="3">
                <v-combobox clearable outlined :label="`${field.relationship.label} *`" v-model="field.relationship.value" :rules="field.relationship.rules" :items="relationshipOptions" />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field outlined :label="`${field.date_birth.label} *`" v-model="field.date_birth.value" :rules="field.date_birth.rules" v-mask="'##/##/####'" />
              </v-col>
              <v-col cols="12" sm="2">
                <v-btn outlined class="py-6 red white--text" @click="removeDependent(index)">Remover</v-btn>
              </v-col>
            </v-row>

            <v-row class="d-flex">
              <v-col cols="12" sm="12">
                <v-btn outlined class="py-6 secondary white--text" @click="addDependent()">Adicionar dependente</v-btn>
              </v-col>
            </v-row>

          </v-card>

          <v-row class="d-flex">
            <v-col cols="12" sm="12">
              <v-btn outlined class="py-6 primary white--text" @click="update()" :disabled="formLoading">{{ formLoading === true ? 'Por favor, aguarde...' : 'Atualizar dados' }}</v-btn>
            </v-col>
          </v-row>

        </div>

      </v-card>
    </v-dialog>

    <!-- =======================================================
                                Main
    ============================================================-->

    <v-row class="d-flex justify-center align-center">
      <v-col md="12" lg="12" xl="12" class="pa-5">
        <v-card class="pa-10 mb-5">
          <v-row class="d-flex mt-0">
            <v-col sm="12 mb-3">
              <strong>Filtrar registros</strong>
            </v-col>
          </v-row>
          <v-row class="d-flex mt-0">
            <v-col sm="6 pa-1">
              <v-text-field
                outlined
                :label="`${formFieldsFilter.name.label}`"
                v-model="formFieldsFilter.name.value"
                :rules="formFieldsFilter.name.rules"
                />
            </v-col>
            <v-col sm="3 pa-1">
              <v-text-field
                outlined
                :label="`${formFieldsFilter.cpf.label}`"
                v-model="formFieldsFilter.cpf.value"
                :rules="formFieldsFilter.cpf.rules"
                v-mask="'###.###.###-##'"
              />
            </v-col>
            <v-col sm="3 pa-1">
              <v-combobox
                clearable
                outlined
                :label="`${formFieldsFilter.status.label}`"
                v-model="formFieldsFilter.status.value"
                :rules="formFieldsFilter.status.rules"
                :items="statusOptions"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex mt-0">
            <v-col sm="9 pa-1">
              <v-combobox
                clearable
                outlined
                :label="`${formFieldsFilter.bank_code.label}`"
                v-model="formFieldsFilter.bank_code.value"
                :rules="formFieldsFilter.bank_code.rules"
                :items="bankOptions"
              />
            </v-col>
            <v-col sm="3 pa-1">
              <v-text-field
                outlined
                :label="`${formFieldsFilter.bank_registration_number.label}`"
                v-model="formFieldsFilter.bank_registration_number.value"
                :rules="formFieldsFilter.bank_registration_number.rules"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex mt-0">
            <v-col sm="12 pa-1">
              <v-btn outlined class="py-6 secondary white--text" @click="search()">Buscar</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="d-flex align-center">
      <v-col md="6" lg="6" xl="6" class="pl-6">
        <h2>Lista de Associados</h2>
      </v-col>
      <v-col md="6" lg="6" xl="6" class="pr-6 d-flex justify-end">
        <v-btn outlined class="py-6" color="orange" @click="handleExport()">Exportar lista de associados</v-btn>
      </v-col>
    </v-row>

    <tables
      :title="title"
      :columns="columns"
      :linesData="linesData"
      @action="action($event)"
      :actionsOptions="actionsOptions"
    />

    <v-row class="d-flex" v-if="countPages > 0">
      <v-col md="4" lg="4" xl="4" class="pa-5 d-flex justify-left">
        <v-pagination
          v-model="page"
          :length="countPages"
          @input="handlePaginationPageChange"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import associateService from "../../../../service/associateService";
import zipcodeService from "../../../../service/zipcodeService";
import associateHelper from '../../../../helpers/associate'

export default {
  name: "associate",
  components: {
    Tables: () => import("../../../../components/cms/Tables")
  },
  data: () => {
    const validateCPF = (cpf) => {
      cpf = cpf.replace(/[^\d]/g, '');

      if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
          return false;
      }

      let sum = 0;
      for (let i = 0; i < 9; i++) {
          sum += parseInt(cpf.charAt(i)) * (10 - i);
      }

      let remainder = sum % 11;
      let digit1 = remainder < 2 ? 0 : 11 - remainder;

      if (parseInt(cpf.charAt(9)) !== digit1) {
          return false;
      }

      sum = 0;
      for (let i = 0; i < 10; i++) {
          sum += parseInt(cpf.charAt(i)) * (11 - i);
      }

      remainder = sum % 11;
      let digit2 = remainder < 2 ? 0 : 11 - remainder;

      if (parseInt(cpf.charAt(10)) !== digit2) {
          return false;
      }

      return true;
    }

    const rules = {
      required: value => !!value || 'Por favor, preencha este campo.',
      counter4: value => value.length === 4 || 'Por favor, informe 4 dígitos',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Por favor, informe um e-mail válido.'
      },
      phone: value => {
        const pattern = /^\(\d{2}\) \d{5}-\d{4}$/;
        return pattern.test(value) || "Por favor, informe um número de telefone válido";
      },
      date: value => {
        const pattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
        return pattern.test(value) || "Por favor, informe uma data válida (dd/mm/aaaa)";
      },
      cpf: value => {
        const isValid = validateCPF(value);

        if (!isValid) {
          return 'Por favor, informe um CPF válido';
        }

        return true;
      },
      zipcode: value => {
        const pattern = /^\d{2}\.\d{3}-\d{3}$/;
        return pattern.test(value) || "Por favor, informe um CEP válido";
      },
      numbersOnly: value => {
        const isANumber = !isNaN(value);
        return isANumber || "Por favor, informe apenas números";
      }
    }

    const maritalStatusOptions = [
      { text: 'Solteiro', value: 'solteiro' },
      { text: 'Casado', value: 'casado' },
      { text: 'Separado', value: 'separado' },
      { text: 'Divorciado', value: 'divorciado' },
      { text: 'Viúvo', value: 'viuvo' },
    ]

    const relationshipOptions = [
      { text: 'Pai', value: 'pai' },
      { text: 'Mãe', value: 'mae' },
      { text: 'Cônjuge', value: 'Cônjuge' },
      { text: 'Filho', value: 'filho' },
      { text: 'Filha', value: 'filha' },
    ]

    const bankOptions = associateHelper.bankList;

    const statusOptions = [
      { text: 'Aguardando validação', value: 'WAITING_VALIDATION' },
      { text: 'Associação ativa', value: 'ASSOCIATE_ENABLED' },
      { text: 'Associação inativa', value: 'ASSOCIATE_DISABLED' },
    ];

    const formDependentFields = [];

    return {
      rules: rules,
      formDependentFields: formDependentFields,
      snackbar:{
        text:"",
        active:false
      },
      formLoading: false,
      page: 1,
      countPages: 0,
      statusOptions: statusOptions,
      bankOptions: bankOptions,
      maritalStatusOptions: maritalStatusOptions,
      relationshipOptions: relationshipOptions,
      formFieldsFilter: {
        name: {
          label: 'Nome',
          value: "",
          rules: [],
        },
        cpf: {
          label: 'CPF',
          value: "",
          rules: [rules.cpf],
        },
        bank_code: {
          label: 'Banco',
          value: "",
          rules: [],
          modify: 'comboBox',
        },
        bank_registration_number: {
          label: 'Nr. Matrícula',
          value: "",
          rules: [],
        },
        status: {
          label: 'Status',
          value: "",
          rules: [],
          modify: 'comboBox',
        },
      },
      formFields: {
        id: {
          label: 'id',
          value: "",
          rules: [],
          modify: 'hidden',
        },
        currentStatus: {
          label: 'currentStatus',
          value: "",
          rules: [],
          modify: 'hidden',
        },
        status: {
          label: 'status',
          value: "",
          rules: [rules.required],
          modify: 'comboBox',
        },
        name: {
          label: 'Nome',
          value: "",
          rules: [rules.required],
        },
        cpf: {
          label: 'CPF',
          value: "",
          rules: [rules.required, rules.cpf],
        },
        rg: {
          label: 'RG',
          value: "",
          rules: [rules.required],
        },
        email: {
          label: 'E-mail',
          value: "",
          rules: [rules.required, rules.email],
        },
        phone: {
          label: 'Telefone',
          value: "",
          rules: [rules.required, rules.phone],
        },
        date_birth: {
          label: 'Data de nascimento',
          value: "",
          rules: [rules.required, rules.date],
          modify: 'date',
        },
        marital_status: {
          label: 'Estado civil',
          value: "",
          rules: [rules.required],
          modify: 'comboBox',
        },
        nationality: {
          label: 'Nacionalidade',
          value: "",
          rules: [rules.required],
        },
        bank_code: {
          label: 'Banco',
          value: "",
          rules: [rules.required],
          modify: 'comboBox',
        },
        bank_agency: {
          label: 'Nro. Agência',
          value: "",
          rules: [rules.required, rules.numbersOnly, rules.counter4],
        },
        bank_registration_number: {
          label: 'Nr. Matrícula',
          value: "",
          rules: [rules.required],
        },
        bank_job_title: {
          label: 'Cargo',
          value: "",
          rules: [rules.required],
        },
        date_admission: {
          label: 'Data de admissão',
          value: "",
          rules: [rules.required],
          modify: 'date',
        },
        zipcode: {
          label: 'CEP',
          value: "",
          rules: [rules.required, rules.zipcode],
        },
        street: {
          label: 'Rua',
          value: "",
          rules: [rules.required],
        },
        number: {
          label: 'Número',
          value: "",
          rules: [rules.required],
        },
        complement: {
          label: 'Complemento',
          value: "",
          rules: [],
        },
        district: {
          label: 'Bairro',
          value: "",
          rules: [rules.required],
        },
        city: {
          label: 'Cidade',
          value: "",
          rules: [rules.required],
        },
        state: {
          label: 'Estado',
          value: "",
          rules: [rules.required],
        },
      },
      title: "Lista de Associados",
      columns: ["Nome", "CPF","Banco", "Matrícula", "Status", "Ações"],
      actionsOptions: ["preview"],
      linesData: [],
      openDialog: false,
      overlay:false,
      json_meta: [
          [
              {
                  'key': 'charset',
                  'value': 'utf-8'
              }
          ]
      ]
    }
  },
  methods: {
    alertAddressNotFound() {
      this.snackbar.text = "Endereço não encontrado para o CEP informado!";
      this.snackbar.active = true;

      this.formFields.street.value = '';
      this.formFields.district.value = '';
      this.formFields.city.value = '';
      this.formFields.state.value = '';
    },
    removeNonNumeric(inputString) {
      if (!inputString) {
        return null;
      }

      return inputString.replace(/\D/g, '');
    },
    async zipcodeLookup() {
      const zipcode = this.removeNonNumeric(this.formFields.zipcode.value);

      // ex.: 38.022-090
      if (zipcode && zipcode.length === 8) {
        try {
          const { data } = await zipcodeService.get(zipcode);

          if (data?.erro === true) {
            this.alertAddressNotFound()
          } else {
            this.formFields.street.value = data.logradouro;
            this.formFields.district.value = data.bairro;
            this.formFields.city.value = data.localidade;
            this.formFields.state.value = data.uf;
            // this.$refs.addressNumber.focus();
          }
        } catch (error) {
          this.alertAddressNotFound()
        }
      }
    },
    getFilterParams() {
      const filter = {}

      Object
        .keys(this.formFieldsFilter)
        .map(field => {
          const fieldValue = this.formFieldsFilter[field].value;
          const fieldModify = this.formFieldsFilter[field].modify;

          filter[field] = this.formFieldsFilter[field].value

          if (fieldModify === 'comboBox' && fieldValue?.value) {
            filter[field] = fieldValue?.value;
          }
        })

      return filter;
    },
    handleExport() {
      this.overlay = true;
      const filter = this.getFilterParams()

      associateService.getExport(filter).then(response => {
        const blob = new Blob(
          [response.data],
          { type: response.headers['content-type'] }
        );

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Lista-Associados.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.overlay = false;
      });
    },
    search() {
      this.page = 1;
      this.getAssociateList();
    },
    getAssociateList() {
      this.overlay = true;
      const filter = this.getFilterParams()

      associateService.getPaginated(filter, this.page).then(res => {
        const templateResponse = res.data;
        this.countPages = templateResponse.countPages;

        if (templateResponse?.data) {
          this.linesData = templateResponse.data.map(associate => {
            return {
              id: associate.id,
              email: associate.email,
              lines: [
                { width: 30, name: associate.name },
                { width: 20, name: associate.cpf },
                { width: 20, name: associate.bank_name },
                { width: 15, name: associate.bank_registration_number },
                { width: 15, name: this.getStatusName(associate.status) }
              ]
            };
          });
        }

        this.overlay = false
      });
    },
    getStatusName(status) {
      const findStatus = this.statusOptions.find(s => s.value === status)

      if (findStatus) {
        return findStatus.text
      }

      return null
    },
    getBankNameByCode(code) {
      const findBank = this.bankOptions.find(bank => bank.value === code);

      if (!findBank) {
        return null;
      }

      return findBank.text;
    },
    getObjectValueFromOptionsList(value, list) {
      return list.find(s => s.value === value)
    },
    convertDbDateToBR(datetime) {
      if (!datetime) {
        return null
      }
0
      const parts = datetime.split('T');
      const date = parts[0];
      const dateSplit = date.split('-');

      return `${dateSplit[2].padStart(2, '0')}/${dateSplit[1].padStart(2, '0')}/${dateSplit[0]}`
    },
    clearDependentList() {
      this.formDependentFields = [];
    },
    addDependent(values) {
      this.formDependentFields.push({
        name: {
          label: 'Nome',
          value: values?.name,
          rules: [this.rules.required],
        },
        relationship: {
          label: 'Relacionamento',
          value: this.getObjectValueFromOptionsList(values?.relationship, this.relationshipOptions),
          rules: [this.rules.required],
          modify: 'comboBox',
        },
        date_birth: {
          label: 'Data de nascimento',
          value: this.convertDbDateToBR(values?.date_birth),
          rules: [this.rules.required, this.rules.date],
          modify: 'date',
        },
      });
    },
    removeDependent(index) {
      this.formDependentFields.splice(index, 1);
    },
    getAssociate(id) {
      this.overlay = true;

      associateService
        .getById(id)
        .then(res => {
          const values = res.data;

          Object
            .keys(this.formFields)
            .map(field => {
              const fieldModify = this.formFields[field].modify;
              const value = values[field]

              if (fieldModify === 'comboBox') {
                let tempValue;

                if (field === 'bank_code') {
                  tempValue = this.getObjectValueFromOptionsList(value, this.bankOptions);
                }

                if (field === 'marital_status') {
                  tempValue = this.getObjectValueFromOptionsList(value, this.maritalStatusOptions);
                }

                if (field === 'status') {
                  tempValue = this.getObjectValueFromOptionsList(value, this.statusOptions);
                }

                this.formFields[field].value = tempValue;
              } else if (fieldModify === 'date' && value) {
                this.formFields[field].value = this.convertDbDateToBR(value);
              } else {
                this.formFields[field].value = value
              }
            })

          this.formFields.currentStatus.value = values.status;
          this.clearDependentList();
          values.dependents?.map(dependent => this.addDependent(dependent));

          this.openDialog = true;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    handlePaginationPageChange() {
      this.getAssociateList();
    },
    action($event) {
      switch ($event.type) {
        case "preview":
          this.getAssociate($event.id)
          break;
        default:
          break;
      }
    },
    update() {
      this.formLoading = true;
      const formValues = {}
      const formDependentValues = []
      const fieldListError = {}
      const fieldListDependentError = []

      Object
        .keys(this.formFields)
        .forEach(field => {
          const fieldLabel = this.formFields[field].label;
          const fieldValue = this.formFields[field].value;
          const fieldRules = this.formFields[field].rules;
          const fieldModify = this.formFields[field].modify;

          formValues[field] = fieldValue;

          if (fieldModify === 'date' && fieldValue) {
            const parts = fieldValue.split('/');
            formValues[field] = parts[2] + '-' + parts[1].padStart(2, '0') + '-' + parts[0].padStart(2, '0');
          }

          if (fieldModify === 'comboBox' && fieldValue?.value) {
            formValues[field] = fieldValue?.value;
          }

          fieldRules.forEach(rule => {
            if (rule(fieldValue) !== true) {
              fieldListError[fieldLabel] = fieldLabel;
            }
          });
        });

      for (let i = 0; i < this.formDependentFields.length; i++) {
        var item = this.formDependentFields[i];
        const formDependentValuesTemp = {}

        Object
          .keys(item)
          .forEach(field => {
            const fieldValue = item[field].value;
            const fieldRules = item[field].rules;
            const fieldModify = item[field].modify;

            formDependentValuesTemp[field] = fieldValue;

            if (fieldModify === 'date' && fieldValue) {
              const parts = fieldValue.split('/');
              formDependentValuesTemp[field] = parts[2] + '-' + parts[1].padStart(2, '0') + '-' + parts[0].padStart(2, '0');
            }

            if (fieldModify === 'comboBox' && fieldValue?.value) {
              formDependentValuesTemp[field] = fieldValue?.value;
            }

            fieldRules.forEach(rule => {
              if (rule(fieldValue) !== true) {
                if (!fieldListDependentError.includes(i + 1)) {
                  fieldListDependentError.push(i + 1)
                }
              }
            });
          });

          formDependentValues.push(formDependentValuesTemp);
      }

      if (Object.keys(fieldListError).length > 0) {
        const fieldListText = Object.keys(fieldListError).join(', ');

        this.snackbar.text = `Por favor, verifique novamente os campos [${fieldListText}] do formulário.`;
        this.snackbar.active = true;
        this.formLoading = false;

        return;
      }

      if (fieldListDependentError.length > 0) {
        const fieldListText = fieldListDependentError.join(', ');

        this.snackbar.text = `Por favor, verifique novamente os dependentes [${fieldListText}] no formulário.`;
        this.snackbar.active = true;
        this.formLoading = false;

        return;
      }

      formValues.bank_name = this.getBankNameByCode(formValues.bank_code);
      formValues.dependents = formDependentValues
      const associateId = formValues.id

      associateService
        .update(formValues, associateId)
        .then((response) => {
          if (response?.data?.status === 'error') {
            this.snackbar.text = response?.data?.message;
          } else {
            this.snackbar.text = "Dados atualizados com sucesso!";
          }

          this.getAssociateList();

          this.openDialog = false;
          this.snackbar.active = true;
        })
        .catch(error => {
          console.log('error saving associate form', error);
          this.snackbar.text = "Aconteceu algum erro inesperado no momento de enviar os dados!";
          this.snackbar.active = true;
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
  mounted() {
    this.getAssociateList();
  }
};
</script>

<style>
  .v-main {
    padding-top: 0 !important;
  }
  .v-toolbar {
    display: none !important;
  }
</style>
